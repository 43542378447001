/* body {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.marquee {
  width: 100%;
  background: #333333;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  font-family: "GT-America-Extended-Regular";
  font-weight: medium;
}

.marquee div {
  font-size: 3rem;
  font-family: verdana;
  padding-left: 100%;
  display: inline-block;
  animation: animate 10s linear infinite;
  color: white;
  letter-spacing: 10px;
}

@keyframes animate {
  100% {
    transform: translate(-100%, 0);
  }
}
