@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:wght@200;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.carousel-inner {
  height: 100%;
}

@font-face {
  font-family: "GT-America-Extended-Regular";
  src: local("GT-America-Extended-Regular"),
    url(/static/media/GT-America-Extended-Regular.1367763c.ttf) format("truetype");
  font-weight: medium;
}

@font-face {
  font-family: "GT-America-Bold";
  src: local("GT-America-Bold"), url(/static/media/GT-America-Bold.994e78a6.ttf) format("truetype");
  font-weight: bold;
}

.maindiv {
  position: fixed;
  border-top: 6px black solid;
  border-left: 6px black solid;
  right: 0;
  bottom: 0;
  z-index: 1000;
}
.container {
  margin-top: 0px;
  text-align: center;
  display: flex;

  padding: 0;

  height: 65px;
  justify-content: center;
}

@media (max-width: 600px) {
  .container {
    display: none;
  }
  .maindiv {
    display: none;
  }
}

.everlib-logo {
  display: inline-block;
  cursor: pointer;
  background: transparent;
  display: flex;
  grid-gap: 3px;
  gap: 3px;
  justify-content: center;
  align-items: center;
}

.everlib-logo .everlib-logo-first-bar,
.everlib-logo .everlib-logo-second-bar,
.everlib-logo .everlib-logo-third-bar,
.everlib-logo .everlib-logo-fourth-bar {
  display: inline-block;
  width: 2px;
  margin: 0;
  padding: 0;
  transition: 0.5s;
  -webkit-animation: equalize 4s 0s infinite;
          animation: equalize 4s 0s infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  vertical-align: top;
  background: black;
}
.everlib-logo .everlib-logo-first-bar {
  height: 10px;
  transition: 0.5s;
  -webkit-animation-delay: -1.9s;
          animation-delay: -1.9s;
}
.everlib-logo .everlib-logo-second-bar {
  height: 18px;
  -webkit-animation-delay: -2.9s;
          animation-delay: -2.9s;
}
.everlib-logo .everlib-logo-third-bar {
  height: 14px;
  -webkit-animation-delay: -3.9s;
          animation-delay: -3.9s;
}
.everlib-logo .everlib-logo-fourth-bar {
  height: 9px;
  -webkit-animation-delay: -4.9s;
          animation-delay: -4.9s;
}

@-webkit-keyframes equalize {
  0% {
    height: 18px;
  }
  4% {
    height: 13px;
  }
  8% {
    height: 12px;
  }
  12% {
    height: 10px;
  }
  16% {
    height: 5px;
  }
  20% {
    height: 30px;
  }
  24% {
    height: 12px;
  }
  28% {
    height: 3.5px;
  }
  32% {
    height: 12px;
  }
  36% {
    height: 18px;
  }
  40% {
    height: 5px;
  }
  44% {
    height: 12px;
  }
  48% {
    height: 18px;
  }
  52% {
    height: 5px;
  }
  56% {
    height: 10px;
  }
  60% {
    height: 5px;
  }
  64% {
    height: 18px;
  }
  68% {
    height: 5px;
  }
  72% {
    height: 18px;
  }
  76% {
    height: 5px;
  }
  80% {
    height: 18px;
  }
  84% {
    height: 9px;
  }
  88% {
    height: 18px;
  }
  92% {
    height: 5px;
  }
  96% {
    height: 18px;
  }
  100% {
    height: 18px;
  }
}

@keyframes equalize {
  0% {
    height: 18px;
  }
  4% {
    height: 13px;
  }
  8% {
    height: 12px;
  }
  12% {
    height: 10px;
  }
  16% {
    height: 5px;
  }
  20% {
    height: 30px;
  }
  24% {
    height: 12px;
  }
  28% {
    height: 3.5px;
  }
  32% {
    height: 12px;
  }
  36% {
    height: 18px;
  }
  40% {
    height: 5px;
  }
  44% {
    height: 12px;
  }
  48% {
    height: 18px;
  }
  52% {
    height: 5px;
  }
  56% {
    height: 10px;
  }
  60% {
    height: 5px;
  }
  64% {
    height: 18px;
  }
  68% {
    height: 5px;
  }
  72% {
    height: 18px;
  }
  76% {
    height: 5px;
  }
  80% {
    height: 18px;
  }
  84% {
    height: 9px;
  }
  88% {
    height: 18px;
  }
  92% {
    height: 5px;
  }
  96% {
    height: 18px;
  }
  100% {
    height: 18px;
  }
}

/* body {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.marquee {
  width: 100%;
  background: #333333;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  font-family: "GT-America-Extended-Regular";
  font-weight: medium;
}

.marquee div {
  font-size: 3rem;
  font-family: verdana;
  padding-left: 100%;
  display: inline-block;
  -webkit-animation: animate 10s linear infinite;
          animation: animate 10s linear infinite;
  color: white;
  letter-spacing: 10px;
}

@-webkit-keyframes animate {
  100% {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
  }
}

@keyframes animate {
  100% {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
  }
}

* {
  margin: 0;
  padding: 0%;
  font-family: "Bebas Neue", cursive;
  box-sizing: content-box;
}


.main {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.welTxt{
  font-family: 'Poppins', sans-serif;
  font-size: 2rem;
  font-weight: 600;
}
.testing{
  width: 50%;
  border: 1px solid rgba(57, 57, 57, 0.34);
  margin-top: 4%;
  border-radius: 5px;
  min-height: 40px;
  padding: 10px;
  font-family: 'Poppins', sans-serif;
}

.testing::-webkit-input-placeholder{
  /* padding: 10px;
  margin: 10px; */
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
}

.testing::placeholder{
  /* padding: 10px;
  margin: 10px; */
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
}

.SubmitBtn{
  margin-top: 5%;
  width: 100%;
  background-color: #C9BFFE;
  border: none;
  border-radius: 3px;
  min-height: 40px;
}

.imghere {
  position: fixed;
  left: 0px;
  top: 0px;
  width: auto;
  height: 100%;
  
}
.signupBtn{
  height: 50px;
  width: 120px;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 64px;
  background-color: white;
  border: 2px solid #333333;
  border-top: none;
  border-right: none;
  /* padding: 1%; */
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  cursor: pointer;
  z-index: 10;
  color: "black";

}
@media (max-width: 600px) {
  .mainlog{
   width: 100%;
  }
}
.mainlog {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width:60%;
  margin-top: 64px;
}

button {
  cursor: pointer;
}

/* 
.forget {
  width: fit-content;
  letter-spacing: 0.07rem;
  padding-left: 25rem;
  font-family: "Montserrat", sans-serif;
} */

.mainbtn {
  display: flex;
  margin-top : 3%;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 150px;
}

@media (max-width: 1070px) {
  .imghere {
    display: none;
  }

  .main {
    justify-content: center;
  }
  .mainlog{
    width: 100%;
   }
}


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}
body{
    font-size: 62.5%;
}
.leftMain{
    position: relative;
    top:0;
    left:0;
    width : auto;
    height: 100%;
}
.image{
    width: auto;
    height:100%;
}
.navRight{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.signupText{
  font-size: 1.25rem !important;
}
@media (max-width: 400px) {
  .signupText{
    font-size: 1rem !important;
  }
}
.rightMain{
    /* background-color: rgba(255, 0, 0,0.3); */
    margin-left: 5%;
    height: 100%;
    width: 58%;
    margin-right: 2%;
}

@media (max-width: 800px) {
  .leftMain{
    display:none;
  }
  .rightMain{
    width: 100%;
  }
}

.signup {
    color: white;
    background-color: black;
    font-family: 'poppins, sens-serif';
    padding: 6px;
    border: none;
    border-radius: 10px;
}

.passwordText{
    color: #035397;
    
}
.rightDown{
    margin-top: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.testing::-webkit-input-placeholder{


padding: 10px;

}
.testing::placeholder{


padding: 10px;

}
.testing{
  width: 100%;
  border: 1px solid rgba(57, 57, 57, 0.34);
  margin-top: 4%;
  border-radius: 5px;
  min-height: 40px;
  padding: 10px;
  font-family: 'Poppins', sans-serif;
}
.DetTxt{
margin-top: 4%;
font-family: 'Poppins', sans-serif;
font-weight: 600;
font-size: .9rem;
letter-spacing: 1px;
}

.para{
  font-size: .75rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #B6B6B6;
  margin-top: 4%;
  letter-spacing: 1px;
  margin-bottom: 5px;
}




.main {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.imghere {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 40%;
  height: 100%;
  
}
.mainlog {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width:60%;
  margin-top: 64px;
}
@media (max-width: 600px) {
  .mainlog{
    width:100%;
  }
}
button {
  cursor: pointer;
}

/* 
.forget {
  width: fit-content;
  letter-spacing: 0.07rem;
  padding-left: 25rem;
  font-family: "Montserrat", sans-serif;
} */

.mainbtn {
  display: flex;
  margin-top : 3%;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 150px;
}

@media (max-width: 1080px) {
  .imghere {
    display: none;
  }

  .main {
    justify-content: center;
  }
}


@media (max-width: 1340px) {
  .mainlog{
    /* border: 1px solid rgba(57, 57, 57, 0.34); */
    padding: 6%;
    align-items: flex-end;
    width: 55%;

  }
}
@media (max-width: 1080px) {
  .mainlog{

    align-items: center;
    width: 60%;
    
  }
}

