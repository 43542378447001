.maindiv {
  position: fixed;
  border-top: 6px black solid;
  border-left: 6px black solid;
  right: 0;
  bottom: 0;
  z-index: 1000;
}
.container {
  margin-top: 0px;
  text-align: center;
  display: flex;

  padding: 0;

  height: 65px;
  justify-content: center;
}

@media (max-width: 600px) {
  .container {
    display: none;
  }
  .maindiv {
    display: none;
  }
}

.everlib-logo {
  display: inline-block;
  cursor: pointer;
  background: transparent;
  display: flex;
  gap: 3px;
  justify-content: center;
  align-items: center;
}

.everlib-logo .everlib-logo-first-bar,
.everlib-logo .everlib-logo-second-bar,
.everlib-logo .everlib-logo-third-bar,
.everlib-logo .everlib-logo-fourth-bar {
  display: inline-block;
  width: 2px;
  margin: 0;
  padding: 0;
  transition: 0.5s;
  animation: equalize 4s 0s infinite;
  animation-timing-function: linear;
  vertical-align: top;
  background: black;
}
.everlib-logo .everlib-logo-first-bar {
  height: 10px;
  transition: 0.5s;
  animation-delay: -1.9s;
}
.everlib-logo .everlib-logo-second-bar {
  height: 18px;
  animation-delay: -2.9s;
}
.everlib-logo .everlib-logo-third-bar {
  height: 14px;
  animation-delay: -3.9s;
}
.everlib-logo .everlib-logo-fourth-bar {
  height: 9px;
  animation-delay: -4.9s;
}

@keyframes equalize {
  0% {
    height: 18px;
  }
  4% {
    height: 13px;
  }
  8% {
    height: 12px;
  }
  12% {
    height: 10px;
  }
  16% {
    height: 5px;
  }
  20% {
    height: 30px;
  }
  24% {
    height: 12px;
  }
  28% {
    height: 3.5px;
  }
  32% {
    height: 12px;
  }
  36% {
    height: 18px;
  }
  40% {
    height: 5px;
  }
  44% {
    height: 12px;
  }
  48% {
    height: 18px;
  }
  52% {
    height: 5px;
  }
  56% {
    height: 10px;
  }
  60% {
    height: 5px;
  }
  64% {
    height: 18px;
  }
  68% {
    height: 5px;
  }
  72% {
    height: 18px;
  }
  76% {
    height: 5px;
  }
  80% {
    height: 18px;
  }
  84% {
    height: 9px;
  }
  88% {
    height: 18px;
  }
  92% {
    height: 5px;
  }
  96% {
    height: 18px;
  }
  100% {
    height: 18px;
  }
}
