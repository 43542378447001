@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}
body{
    font-size: 62.5%;
}
.leftMain{
    position: relative;
    top:0;
    left:0;
    width : auto;
    height: 100%;
}
.image{
    width: auto;
    height:100%;
}
.navRight{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.signupText{
  font-size: 1.25rem !important;
}
@media (max-width: 400px) {
  .signupText{
    font-size: 1rem !important;
  }
}
.rightMain{
    /* background-color: rgba(255, 0, 0,0.3); */
    margin-left: 5%;
    height: 100%;
    width: 58%;
    margin-right: 2%;
}

@media (max-width: 800px) {
  .leftMain{
    display:none;
  }
  .rightMain{
    width: 100%;
  }
}

.signup {
    color: white;
    background-color: black;
    font-family: 'poppins, sens-serif';
    padding: 6px;
    border: none;
    border-radius: 10px;
}

.passwordText{
    color: #035397;
    
}
.rightDown{
    margin-top: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
}