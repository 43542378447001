.testing::placeholder{


padding: 10px;

}
.testing{
  width: 100%;
  border: 1px solid rgba(57, 57, 57, 0.34);
  margin-top: 4%;
  border-radius: 5px;
  min-height: 40px;
  padding: 10px;
  font-family: 'Poppins', sans-serif;
}
.DetTxt{
margin-top: 4%;
font-family: 'Poppins', sans-serif;
font-weight: 600;
font-size: .9rem;
letter-spacing: 1px;
}

.para{
  font-size: .75rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #B6B6B6;
  margin-top: 4%;
  letter-spacing: 1px;
  margin-bottom: 5px;
}




.main {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.imghere {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 40%;
  height: 100%;
  
}
.mainlog {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width:60%;
  margin-top: 64px;
}
@media (max-width: 600px) {
  .mainlog{
    width:100%;
  }
}
button {
  cursor: pointer;
}

/* 
.forget {
  width: fit-content;
  letter-spacing: 0.07rem;
  padding-left: 25rem;
  font-family: "Montserrat", sans-serif;
} */

.mainbtn {
  display: flex;
  margin-top : 3%;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 150px;
}

@media (max-width: 1080px) {
  .imghere {
    display: none;
  }

  .main {
    justify-content: center;
  }
}


@media (max-width: 1340px) {
  .mainlog{
    /* border: 1px solid rgba(57, 57, 57, 0.34); */
    padding: 6%;
    align-items: flex-end;
    width: 55%;

  }
}
@media (max-width: 1080px) {
  .mainlog{

    align-items: center;
    width: 60%;
    
  }
}
