@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:wght@200;400&display=swap");
* {
  margin: 0;
  padding: 0%;
  font-family: "Bebas Neue", cursive;
  box-sizing: content-box;
}


.main {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.welTxt{
  font-family: 'Poppins', sans-serif;
  font-size: 2rem;
  font-weight: 600;
}
.testing{
  width: 50%;
  border: 1px solid rgba(57, 57, 57, 0.34);
  margin-top: 4%;
  border-radius: 5px;
  min-height: 40px;
  padding: 10px;
  font-family: 'Poppins', sans-serif;
}

.testing::placeholder{
  /* padding: 10px;
  margin: 10px; */
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
}

.SubmitBtn{
  margin-top: 5%;
  width: 100%;
  background-color: #C9BFFE;
  border: none;
  border-radius: 3px;
  min-height: 40px;
}

.imghere {
  position: fixed;
  left: 0px;
  top: 0px;
  width: auto;
  height: 100%;
  
}
.signupBtn{
  height: 50px;
  width: 120px;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 64px;
  background-color: white;
  border: 2px solid #333333;
  border-top: none;
  border-right: none;
  /* padding: 1%; */
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  cursor: pointer;
  z-index: 10;
  color: "black";

}
@media (max-width: 600px) {
  .mainlog{
   width: 100%;
  }
}
.mainlog {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width:60%;
  margin-top: 64px;
}

button {
  cursor: pointer;
}

/* 
.forget {
  width: fit-content;
  letter-spacing: 0.07rem;
  padding-left: 25rem;
  font-family: "Montserrat", sans-serif;
} */

.mainbtn {
  display: flex;
  margin-top : 3%;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 150px;
}

@media (max-width: 1070px) {
  .imghere {
    display: none;
  }

  .main {
    justify-content: center;
  }
  .mainlog{
    width: 100%;
   }
}

