@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.carousel-inner {
  height: 100%;
}

@font-face {
  font-family: "GT-America-Extended-Regular";
  src: local("GT-America-Extended-Regular"),
    url("./font/GT-America-Extended-Regular.ttf") format("truetype");
  font-weight: medium;
}

@font-face {
  font-family: "GT-America-Bold";
  src: local("GT-America-Bold"), url("./font/GT-America-Bold.ttf") format("truetype");
  font-weight: bold;
}
